const redirectQuery = () => gql`
    query redirect($url: String!) {
        redirect(url: $url) {
            path
            center
            locale
        }
    }
`;

export default redirectQuery;
