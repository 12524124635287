const pageQuery = (gqlFragment) => {
    const fragmentName = gqlFragment.definitions[0].name.value;

    const query = gql`
        query page($domain: String!, $path: String!, $collection: String!, $center: String, $token: String, $locale: LocaleInputType) {
            page(domain: $domain, path: $path, collection: $collection, token: $token, center: $center, locale: $locale) {
                    ...${fragmentName}
                }
            }

        ${gqlFragment}
    `;

    return query;
};

export default pageQuery;
